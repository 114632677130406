import axios from "axios"
import {
  API_URL,
  API_MD_URL,
  API_URL_CUSTOMERS,
  API_URL_PRICES,
  API_URL_CUSTOMERS_NO_PROXY,
  API_URL_INVENTORY,
  API_URL_ROLES,
  API_URL_LOC_HIERARCHY,
  API_URL_PRODUCT,
  API_URL_CUSTOMERS_COMMON,
  API_KC_URL,
  API_UM_URL,
  API_CM_URL,
  API_DASHBOARD_URL,
  API_DOMAIN_BASE_URL,
  API_DEVICES_URL,
  SAMPLE_FILE_TEMPLATE,
} from "../../utils/url"

import { axiosConfig } from "../apiHeaderService"
import UserService from "../UserService"
import jwt_decode from "jwt-decode"

const headerAxios = axios.create()
const headerAxiosziptastic = axios.create()
const subscriptionAxios = axios.create()
const SaaSCommonAxios = axios.create()

subscriptionAxios.interceptors.request.use(
  async function (config) {
    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          config.headers["Authorization"] = "Bearer " + mydataToken
          config.headers["X-KC-Realm-ID"] =
            axiosConfig.headers["X-KC-Realm-ID"] || ""
          config.headers["X-KC-Host"] = axiosConfig.headers["X-KC-Host"] || ""

          return Promise.resolve(config)
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
        config.headers["X-KC-Realm-ID"] =
          axiosConfig.headers["X-KC-Realm-ID"] || ""
        config.headers["X-KC-Host"] = axiosConfig.headers["X-KC-Host"] || ""

        return Promise.resolve(config)
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }
  },
  function (error) {
    return Promise.reject(error)
  }
)

headerAxios.interceptors.request.use(
  async function (config) {
    config.headers["language-code"] = localStorage.getItem("i18nextLng") || "en"
    let isSAASROLE = localStorage.getItem("role")?.includes("SaaS-Site-Admin")
    let isDashboardAPI = config.url.includes(
      "api/dashboardInfo/tenant/getTenantDeviceConfig"
    )
    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          let tempTenantId = localStorage.getItem("tempTenantId") || ""
          if (!config.headers["X-Tenant-ID"])
            config.headers["X-Tenant-ID"] =
              isSAASROLE && isDashboardAPI ? "" : tenantId || tempTenantId
          config.headers["Authorization"] = "Bearer " + mydataToken
          config.headers["X-KC-Realm-ID"] =
            axiosConfig.headers["X-KC-Realm-ID"] || ""
          config.headers["X-KC-Host"] = axiosConfig.headers["X-KC-Host"] || ""
          return Promise.resolve(config)
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        let tenantId =
          localStorage.getItem("tenantId") === null
            ? ""
            : localStorage.getItem("tenantId")
        let tempTenantId = localStorage.getItem("tempTenantId") || ""
        if (!config.headers["X-Tenant-ID"])
          config.headers["X-Tenant-ID"] =
            isSAASROLE && isDashboardAPI ? "" : tenantId || tempTenantId
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
        config.headers["X-KC-Realm-ID"] =
          axiosConfig.headers["X-KC-Realm-ID"] || ""
        config.headers["X-KC-Host"] = axiosConfig.headers["X-KC-Host"] || ""
        return Promise.resolve(config)
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }
  },
  function (error) {
    console.log("headerAxios", error)
    return Promise.reject(error)
  }
)

headerAxiosziptastic.interceptors.request.use(
  async function (config) {
    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          config.headers["x-Key"] = "b08143470b1d74904b91b90446dc5fe14543c87b"
          return Promise.resolve(config)
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        config.headers["x-Key"] = "b08143470b1d74904b91b90446dc5fe14543c87b"
        return Promise.resolve(config)
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }
  },
  function (error) {
    console.log("headerAxios", error)
    return Promise.reject(error)
  }
)

SaaSCommonAxios.interceptors.request.use(
  async function (config) {
    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          let isSAASROLE = localStorage
            .getItem("role")
            ?.includes("SaaS-Site-Admin")

          config.headers["X-Tenant-ID"] = isSAASROLE ? "" : tenantId
          config.headers["Authorization"] = "Bearer " + mydataToken
          config.headers["X-KC-Realm-ID"] =
            axiosConfig.headers["X-KC-Realm-ID"] || ""
          config.headers["X-KC-Host"] = axiosConfig.headers["X-KC-Host"] || ""
          return Promise.resolve(config)
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        let tenantId =
          localStorage.getItem("tenantId") === null
            ? ""
            : localStorage.getItem("tenantId")
        let isSAASROLE = localStorage
          .getItem("role")
          ?.includes("SaaS-Site-Admin")
        config.headers["X-Tenant-ID"] = isSAASROLE ? "" : tenantId
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
        config.headers["X-KC-Realm-ID"] =
          axiosConfig.headers["X-KC-Realm-ID"] || ""
        config.headers["X-KC-Host"] = axiosConfig.headers["X-KC-Host"] || ""
        return Promise.resolve(config)
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }
  },
  function (error) {
    console.log("headerAxios", error)
    return Promise.reject(error)
  }
)
const API_BASE_URL =
  process.env.REACT_APP_API_ENDPOINT ||
  "http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com"
const KC_API_BASE_URL =
  process.env.REACT_APP_KC_API_ENDPOINT ||
  "http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com"
const DK_BASE_DOMAIN_URL =
  process.env.REACT_APP_BASE_DOMAIN_URL || "https://stageapi.digitkart.ai"
const DK_BASE_URL =
  process.env.REACT_APP_BASE_DOMAIN_URL || "https://stageapi.digitkart.ai"

export {
  headerAxios,
  API_BASE_URL,
  KC_API_BASE_URL,
  DK_BASE_DOMAIN_URL,
  SaaSCommonAxios,
  axiosConfig,
}

export const getBulkUpload_SampleTemplate = async (
  moduleName,
  langCode,
  locationId
) => {
  try {
    let response = await headerAxios.get(
      API_MD_URL + SAMPLE_FILE_TEMPLATE + `/${moduleName}/${langCode}`,
      {
        headers: {
          // Accept: "application/json",
          // "Content-Type": "application/json",
          "location-id": locationId,
        },
      }
    )
    return response
  } catch (error) {
    return error
  }
}

export const getSubscriptionAxios = async (
  END_POINTS,
  bodyData = {},
  config = {}
) => {
  try {
    let response = await subscriptionAxios.get(
      DK_BASE_URL + END_POINTS,
      bodyData,
      config
    )
    return response
  } catch (error) {
    return error
  }
}

export const getCommonDatabody = async (
  END_POINTS,
  bodyData = {},
  config = {}
) => {
  try {
    let response = await headerAxios.get(
      DK_BASE_URL + END_POINTS,
      bodyData,
      config
    )
    return response
  } catch (error) {
    return error
  }
}

export const doGetFetchAPI = (props) => {
  const {
    END_POINTS = "",
    prefixUrl = "",
    isDomainChange = false,
    baseUrl = "",
  } = props || {}

  const API_BASE_URLs = API_DASHBOARD_URL.replace("", "")
  let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl

  if (isDomainChange) {
    apiURL = process.env.REACT_APP_API_ENDPOINT.replace("/api/apigw", "")
  }

  if (baseUrl) {
    apiURL = baseUrl
  }

  return headerAxios.get(apiURL + END_POINTS)
}
export const doPostFetchAPI = (props) => {
  const {
    END_POINTS = "",
    prefixUrl = "",
    body = {},
    replaceValue = "",
    isDomainChange = false,
    baseUrl = "",
  } = props || {}
  const API_BASE_URLs = API_DASHBOARD_URL.replace("", "")
  let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl

  if (isDomainChange) {
    apiURL = process.env.REACT_APP_API_ENDPOINT.replace("/api/apigw", "")
  }

  if (baseUrl) {
    apiURL = baseUrl
  }

  return headerAxios.post(apiURL + END_POINTS, body)
}

export const doPutFetchAPI = (props) => {
  const {
    END_POINTS = "",
    prefixUrl = "",
    body = {},
    isDomainChange = false,
    baseUrl = "",
  } = props || {}
  const API_BASE_URLs = API_BASE_URL.replace("", "")
  let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl

  if (isDomainChange) {
    apiURL = process.env.REACT_APP_API_ENDPOINT.replace("/api/apigw", "")
  }

  if (baseUrl) {
    apiURL = baseUrl
  }

  return headerAxios.put(apiURL + END_POINTS, body)
}

export const doDeleteFetchAPI = (props) => {
  const {
    END_POINTS = "",
    prefixUrl = "",
    body = {},
    isDomainChange = false,
    baseUrl = "",
  } = props || {}
  const API_BASE_URLs = API_BASE_URL.replace("", "")
  let apiURL = API_BASE_URLs.length > 0 ? API_BASE_URLs : prefixUrl

  if (isDomainChange) {
    apiURL = process.env.REACT_APP_API_ENDPOINT.replace("/api/apigw", "")
  }

  if (baseUrl) {
    apiURL = baseUrl
  }

  return headerAxios.delete(apiURL + END_POINTS)
}

export const getCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_URL + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const updateSaaSCommonData = async (END_POINTS, body) => {
  try {
    let response = await SaaSCommonAxios.patch(API_CM_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const fetchCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await SaaSCommonAxios.post(API_CM_URL + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}

export const deleteCommonDataHeaders = async (END_POINTS, payload) => {
  try {
    let response = await SaaSCommonAxios.delete(
      API_CM_URL + END_POINTS,
      payload
    )
    return response
  } catch (error) {
    return error
  }
}

export const putCommonDataHeaders = async (
  END_POINTS,
  bodyData = {},
  headers = {}
) => {
  try {
    let response = await SaaSCommonAxios.put(
      API_CM_URL + END_POINTS,
      bodyData,
      headers
    )
    return response
  } catch (error) {
    return error
  }
}

export const fetchCommonDataHeaders = async (
  END_POINTS,
  bodyData = {},
  headers = {}
) => {
  try {
    let response = await SaaSCommonAxios.post(
      API_CM_URL + END_POINTS,
      bodyData,
      headers
    )
    return response
  } catch (error) {
    return error
  }
}

export const fetchFrictionlessCommonData = async (
  END_POINTS,
  bodyData = {}
) => {
  try {
    let response = await headerAxios.post(API_KC_URL + END_POINTS, bodyData)
    return response
  } catch (error) {
    console.log("fetchFrictionlessCommonData", error)
    return error
  }
}

export const fetchStoreDashBoardData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_URL + END_POINTS, bodyData)
    console.log("getStoresforDashboard", response)

    return response
  } catch (error) {
    console.log("getStoresforDashboard", error)
    return error
  }
}
export const fetchStoreDashTenantData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_URL + END_POINTS, bodyData)
    console.log("getStoresforDashboard", response)
    return response
  } catch (error) {
    console.log("getStoresforDashboard", error)
    return error
  }
}

export const fetchCommonRoleData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_URL_ROLES + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}

export const fetchKCRoleData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_UM_URL + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}
export const getCommonRoleData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_UM_URL + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const getKCRoleData = async (END_POINTS, config = {}) => {
  try {
    let response = await headerAxios.get(API_UM_URL + END_POINTS, config)
    return response
  } catch (error) {
    return error
  }
}

export const putKCRoleData = async (END_POINTS, data, config = {}) => {
  try {
    let response = await headerAxios.put(API_UM_URL + END_POINTS, data, config)
    return response
  } catch (error) {
    return error
  }
}

export const putCommonRoleData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.put(API_URL_ROLES + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}

export const fetchStoreCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_MD_URL + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}

export const fetchCardTypesList = async (END_POINTS, postObject = {}) => {
  try {
    let response = await headerAxios.post(
      API_DEVICES_URL + END_POINTS,
      postObject
    )
    return response
  } catch (error) {
    return error
  }
}

export const putStoreCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.put(API_MD_URL + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}
export const postStoreCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(API_MD_URL + END_POINTS, bodyData)
    return response
  } catch (error) {
    return error
  }
}
export const getStoreCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_MD_URL + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const postCommonDataConfig = async (
  END_POINTS,
  body,
  realmId,
  tenantId,
  headers = {}
) => {
  try {
    let response = await headerAxios.post(API_MD_URL + END_POINTS, body, {
      headers: {
        "X-Tenant-ID": tenantId,
        ...headers,
      },
    })
    return response
  } catch (error) {
    return error
  }
}

export const fetchCustomerCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(
      API_URL_CUSTOMERS_COMMON + END_POINTS,
      bodyData
    )
    return response
  } catch (error) {
    return error
  }
}

export const getCustomerCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_URL_CUSTOMERS_COMMON + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const putCustomerCommonData = async (END_POINTS, bodyData, id = {}) => {
  try {
    let response = await headerAxios.put(
      API_URL_CUSTOMERS_COMMON + END_POINTS + "/" + id,
      bodyData
    )
    return response
  } catch (error) {
    return error
  }
}

export const postProductCommonData = async (END_POINTS, body = {}) => {
  try {
    let response = await headerAxios.post(API_MD_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const getProductCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(API_MD_URL + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const patchProductCommonData = async (END_POINTS, body = {}) => {
  try {
    let response = await headerAxios.patch(API_MD_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const fetchInventoryCommonData = async (END_POINTS, bodyData = {}) => {
  try {
    let response = await headerAxios.post(
      API_URL_INVENTORY + END_POINTS,
      bodyData
    )
    return response
  } catch (error) {
    return error
  }
}

export const putInventoryCommonData = async (END_POINTS, bodyData) => {
  try {
    let response = await headerAxios.put(
      API_URL_INVENTORY + END_POINTS,
      bodyData
    )
    return response
  } catch (error) {
    return error
  }
}

export const getAllCustomers = async (END_POINTS, page, limit, filter) => {
  try {
    const storesLink =
      filter === ""
        ? API_URL_CUSTOMERS + END_POINTS + "?page=" + page + "&limit=" + limit
        : API_URL_CUSTOMERS +
          END_POINTS +
          "?" +
          filter +
          "&page=" +
          page +
          "&limit=" +
          limit
    let response = await headerAxios.get(storesLink)

    return response
  } catch (error) {
    return { status: "failed" }
  }
}

export const getAllInventory = async (END_POINTS, page, limit = 10, filter) => {
  try {
    const inventoryLink =
      filter === ""
        ? API_URL_INVENTORY + END_POINTS + "?page=" + page + "&limit=" + limit
        : API_URL_INVENTORY +
          END_POINTS +
          "/?" +
          filter +
          "&page=" +
          page +
          "&limit=" +
          limit
    let response = await headerAxios.get(inventoryLink)
    return response
  } catch (error) {
    return { status: "failed" }
  }
}
export const getAllProducts = async (END_POINTS, page, limit, filter) => {
  try {
    const productLink =
      filter === ""
        ? API_URL_PRODUCT + END_POINTS + "?page=" + page + "&limit=" + limit
        : API_URL_PRODUCT +
          END_POINTS +
          "?" +
          filter +
          "&page" +
          page +
          "&limit" +
          limit
    let response = await headerAxios.get(productLink)
    return response
  } catch (error) {
    return { status: "failed" }
  }
}

export const getAllPrices = async (END_POINTS, page, limit, filter) => {
  try {
    const storesLink =
      filter === ""
        ? API_URL_PRICES + END_POINTS + "?page=" + page + "&limit=" + limit
        : API_URL_PRICES +
          END_POINTS +
          "?" +
          filter +
          "&page=" +
          page +
          "&limit=" +
          limit
    let response = await headerAxios.get(storesLink)
    return response
  } catch (error) {
    return { status: "failed" }
  }
}
export const getElasticSearch = async (END_POINTS = "") => {
  try {
    const storesLink = API_URL_PRICES + END_POINTS
    let response = await headerAxios.get(storesLink)
    return response
  } catch (error) {
    return { status: "failed" }
  }
}

export const addNewAddress = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_URL_CUSTOMERS + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const editAddress = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_URL_CUSTOMERS + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const postCommonData = async (END_POINTS, body) => {
  try {
    let response = await SaaSCommonAxios.post(API_CM_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const putCommonData = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.put(API_CM_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const getContentData = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.get(API_CM_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const updateCommonData = async (END_POINTS, body) => {
  try {
    let response = await SaaSCommonAxios.patch(API_CM_URL + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const deleteCommonData = async (END_POINTS) => {
  try {
    let response = await headerAxios.delete(API_URL + END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleCommonData = async (END_POINTS, body) => {
  try {
    let response = await SaaSCommonAxios.delete(API_CM_URL + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}
export const getAllLocation = async (page, limit, filter) => {
  try {
    const storesLink =
      filter === ""
        ? `${API_BASE_URL}/api/stores/stores?page=` + page + "&limit=" + limit
        : `${API_BASE_URL}/api/stores/stores?` +
          filter +
          "&page=" +
          page +
          "&limit=" +
          limit

    let response = await headerAxios.get(storesLink)
    return response
  } catch (error) {
    return error
  }
}

export const getAllLocationGroups = async (page, limit, filter) => {
  try {
    let response = await headerAxios.get(
      `${API_BASE_URL}/api/stores/getallgroups`
    )
    return response
  } catch (error) {
    return error
  }
}

export const getAllStates = async () => {
  try {
    const statesLink = `${API_BASE_URL}/api/stores/getAllRegions`
    let response = await headerAxios.get(statesLink)
    return response
  } catch (error) {
    return error
  }
}

export const postCommonDataURL = async (URL_END_POINTS, body) => {
  try {
    let response = await headerAxios.post(URL_END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const getCommonDataUrlOnId = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const updateCommonDataURLOnId = async (URL_END_POINTS, body) => {
  try {
    let response = await headerAxios.patch(URL_END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const deleteCommonDataUrlOnId = async (END_POINTS) => {
  try {
    let response = await headerAxios.delete(END_POINTS)
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleStores = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(API_MD_URL + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleProducts = async (
  END_POINTS = "",
  body = {},
  prefixUrl = "",
  endpointModule = ""
) => {
  let pathName = `${API_URL_PRODUCT}`
  if (prefixUrl) {
    pathName = `${prefixUrl}`
  }
  if (endpointModule) {
    pathName = `${pathName}${endpointModule}`
  }
  if (END_POINTS) {
    pathName = `${pathName}${END_POINTS}`
  }

  try {
    let response = await headerAxios.delete(pathName, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleProductsPOST = async (
  END_POINTS = "",
  body = {},
  pathName = ""
) => {
  try {
    let response = await headerAxios.post(`${pathName}${END_POINTS}`, body)
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleCustomers = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(
      API_URL_CUSTOMERS_NO_PROXY + END_POINTS,
      {
        data: body,
      }
    )
    return response
  } catch (error) {
    return error
  }
}
export const deleteMultipleInventories = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(API_URL_INVENTORY + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}
export const deleteMultiplePrice = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}

export const createNewInventory = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_URL_INVENTORY + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}
export const getCommonInvontery = async (END_POINTS) => {
  try {
    let response = await headerAxios.get(END_POINTS)
    return response
  } catch (error) {
    return error
  }
}
export const createNewPrice = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.post(API_URL_PRICES + END_POINTS, body)
    return response
  } catch (error) {
    return error
  }
}

export const getLocationHierarchy = async () => {
  try {
    let response = await headerAxios.get(API_URL_LOC_HIERARCHY)
    return response
  } catch (error) {
    return error
  }
}

export const getAllCities = async () => {
  try {
    let response = await headerAxios.get(
      `${API_BASE_URL}/api/stores/getAllCities`
    )
    return response
  } catch (error) {
    return error
  }
}

export const deleteMultipleAttibutes = async (END_POINTS, body) => {
  try {
    let response = await headerAxios.delete(API_BASE_URL + END_POINTS, {
      data: body,
    })
    return response
  } catch (error) {
    return error
  }
}

export const bulkUploadProducts = async (API_MODULE, END_POINTS, body) => {
  let apiUrl = `${DK_BASE_DOMAIN_URL}${API_MODULE}${END_POINTS}`
  let data = new FormData()
  data.append("payload", JSON.stringify(body || []))
  try {
    let response = await headerAxios.post(apiUrl, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response
  } catch (error) {
    return error
  }
}

export const fetchZipcodeDetails = async (zipcode, timeZone) => {
  try {
    let response = await headerAxios.post(
      `${DK_BASE_URL}/api/masterdata/api/stores/${zipcode}/TZ/${timeZone}`
    )
    return response
  } catch (error) {
    console.log("fetchZipcodeDetails", error)
    return error
  }
}

export const fetchTranslationData = async (languages, direction = null) => {
  //OLD const apiUrl = `${API_CM_URL}/api/tenant/language/getTenantLanaguages`
  const apiUrl = `${API_CM_URL}/api/tenant/language/getTenantLanaguagesLists`
  let requestBody = {
    lanuages: languages,
  }
  if (direction !== null) {
    requestBody["display_direction"] = direction
  }
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
    const responseData = await response.json()
    console.log(responseData.data)
    return responseData.data
  } catch (error) {
    console.error("Error fetching translation data:", error)
    return error
  }
}
